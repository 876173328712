<template>
    <v-container>
        <div class="user" v-if="loggedin && user " >
            <div v-if="$store.state.isAdmin && userLocal!==undefined && userLocal.profile!==undefined && userLocal.profile.status!==undefined" >
                <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                >
                    <v-row align-content="center">
                      <v-col class="col-sm-10 col-12" align-self="center">
                        <v-select id="status"
                                  v-if="$store.state.statusProfile"
                                  v-model="userLocal.profile.status"
                                  :items="$store.state.statusProfile.filter(s => s.name === 'REVIEW' ||  s.name === 'REJECTED' ||  s.name === 'APPROVED')"
                                  item-text="displayName"
                                  :return-object=true
                                  label="Status"
                                  :rules="[v => !!v  || 'This field is required!']"
                        ></v-select>
                      </v-col>
                      <v-col class="col-sm-2 col-12">
                        <v-btn id="btn_admin_save" outlined :class="{ 'warning--text ':this.$root.self===false,'':this.$root.self===true} " color="primary" class="margin-left-right-default" :disabled="!valid"  @click="validate()!=true?null:save()" :loading="loading">Save</v-btn>
                      </v-col>
                    </v-row>

                <v-textarea id="comments" label="Comments" v-if="userLocal.profile.status.name==='REVIEW' || userLocal.profile.status.name==='REJECTED'"
                            v-model="userLocal.profile.comments"
                            :rules="[v => !!v  || 'This field is required!']">
                </v-textarea>

                </v-form>
            </div>
<!--            {{chef.name}}-->
            <router-view></router-view>
<!--            <h2>User {{ $route.params.id }} - {{$root.showDrawerDashboard}} - {{$parent.permanent}} - </h2>-->

        </div>
        <v-dialog v-if="dirty" v-model="dirty" persistent max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">Do you really want to leave? </span>
                </v-card-title>
                <v-card-text>
                    You have unsaved changes!
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined @click="dirty=false" class="margin-left-right-default">No</v-btn>
                    <v-btn @click="dirty=false;next()" color="primary" class="margin-right-default">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
    import Constants from '../constants';
    import {mapGetters} from 'vuex';
    export default {
        name: "Dashboard",
        data() {
            return {
              loadingUser:false,
                userLocal:undefined,
                loading:false,
                valid:undefined,
                dirty:false,
                next:undefined,
                self:false
            };
        },
        created() {
          this.$root.showDrawerDashboard = true;
          if ( this.$route.params.id!==undefined ) {
            this.$root.self=false;
          }else{
            this.$root.self=true;
          }
        },
        mounted(){
          // if ( this.$route.params.id!==undefined ) {
          //     this.$store.dispatch('getUser', this.$route.params.id);
          // }else{
          //   // reload user
          //   if (this.$store.getters.getUser!==undefined){
          //     this.$store.dispatch('getUser', this.$store.getters.getUser.id);
          //   }
          // }
        },
        destroyed(){
            this.$root.showDrawerDashboard=false;
            console.log("destroyed");
        },
        methods:{
            initUserLocal(user) {
              // if (self!==undefined) {
              //   this.$store.state.self = self;
              // }
              if (this.userLocal===undefined){
                this.userLocal={};
              }
              if (user!=undefined){
                  let copy=JSON.parse(JSON.stringify(user));
                  this.userLocal=copy;
                  // this.$set(this.userLocal, 'profile',copy.profile);
              }
              this.loadingUser=false;
            },
            save(context) {
              if (context===undefined){
                context=this;
              }
                console.log("save");
              console.log(context.userLocal);
              delete context.userLocal.validationMessages;
              let that=context;
              that.loading=true;
              that.$store.dispatch('saveUser', context.userLocal)
                  .then((response) => {
                    if (response !== undefined && response.statusCode === 200) {
                      if ( response.validationMessages!==undefined){
                        let text=Constants.SAVED+"\n";
                        Object.values(response.validationMessages).forEach(i=>{text+=i+"\n"});
                        that.$store.dispatch('showSnackbar', {
                          text: text,
                          color: "primary",
                          timeout: 100000
                        });
                      }else{
                        that.$store.dispatch('showSnackbar', {
                          text: Constants.SAVED,
                          color: "primary"
                        })
                      }
                    }
                  }).finally(()=>{that.loading=false;});
            },
            validate () {
                if (this.$refs.form!==undefined){
                    return this.$refs.form.validate();
                }
            },
          async getUser(){
            this.loadingUser=true;
            let response=await this.$store.dispatch('getUser', this.$route.params.id);
            this.initUserLocal(response)
            return response;
          }
        },
        computed: {
            ...mapGetters([
                'findChef',
                'isLoaded',
                'isAdmin'
            ]),
            chefId() {
                return this.$route.params.id;
            },
            chef() {
                let chef = this.findChef(`${this.chefId}`);
                return chef;
            },
          loggedin(){
           return this.$store.state.loggedin;
          },
           user() {
                // let user=this.$store.state.user;
             if (this.loadingUser){
               return undefined;
             }
             let user;

              if (this.$store.state.isAdmin && this.$route.params.id!==undefined /*&& parseInt(this.$route.params.id)!==this.$store.getters.getUser.id*/){

                  if (this.$store.state.workingUser!==undefined && this.$store.state.workingUser.id===this.$route.params.id ){
                      user=this.$store.state.workingUser;
                      this.initUserLocal(user);
                      return user;
                  }else{
                    console.log("retrieve user");
                    this.getUser();
                    // this.$store.dispatch('getUser', this.$route.params.id);
                    return undefined;
                  }
              }else{
                user=this.$store.getters.getUser;
                if (user.service!==undefined ){
                  user.service.forEach(service => service.itemCategories!==undefined ? service.itemCategories.sort(((a, b) => a.ordering - b.ordering)):void 0);

                }
                this.initUserLocal(user);
                return user;
                // if (this.$store.state.workingUser!==undefined ){
                //   console.log("updated user");
                //   user=this.$store.state.workingUser;
                //   this.initUserLocal(user);
                //   return user;
                // }
              }
              // user=this.$store.getters.getUser;
              // this.initUserLocal(user);
              // return user;
            },
            // test() {
            //     let user=this.$store.getters.getUser;
            //     return user;
            // },
        },
    }
</script>

<style scoped>

</style>