<template>
    <v-container>
<!--      user:{{user}}-->
<!--      userlocal:{{userLocal}}-->
            <router-view></router-view>
    </v-container>
</template>

<script>
    import {} from 'vuex';
    import Constants from "@/constants";
    export default {
      name: "Account",
      data() {
        return {
          userLocal:{},
          loading:false,
          valid:undefined,
          dirty:false,
          next:undefined,
          self:false
        };
      },
      created() {
          this.$root.showDrawerAccount = true;
        if ( this.$route.params.id!==undefined ) {
          this.$root.self=false;
        }else{
          this.$root.self=true;
        }
      },
      mounted(){
        if (this.$store.state.loggedin===false){
          return this.$router.push({path: `./`})
        }
        if ( this.$route.params.id!==undefined ) {
          this.$store.dispatch('getUser', this.$route.params.id);
        }
      },
      destroyed(){
          this.$root.showDrawerAccount=false;
          console.log("destroyed");
      },
      methods: {
        initUserLocal(user) {
          if (this.userLocal===undefined){
            this.userLocal={};
          }
          if (user != undefined) {
            let copy = JSON.parse(JSON.stringify(user));
            this.userLocal = copy;
            // this.$set(this.userLocal, 'profile',copy.profile);
          }
          this.loadingUser=false;
        },
        save(context) {
          if (context===undefined){
            context=this;
          }
          console.log("save");
          console.log(context.userLocal);
          delete context.userLocal.validationMessages;
          let that=context;
          that.loading=true;
          that.$store.dispatch('saveUser', context.userLocal)
              .then((response) => {
                if (response !== undefined && response.statusCode === 200) {
                  if ( response.validationMessages!==undefined){
                    let text=Constants.SAVED+"\n";
                    Object.values(response.validationMessages).forEach(i=>{text+=i+"\n"});
                    that.$store.dispatch('showSnackbar', {
                      text: text,
                      color: "primary",
                      timeout: 100000
                    });
                  }else{
                    that.$store.dispatch('showSnackbar', {
                      text: Constants.SAVED,
                      color: "primary"
                    })
                  }
                }
              }).finally(()=>{that.loading=false;});
        },
        validate () {
          if (this.$refs.form!==undefined){
            return this.$refs.form.validate();
          }
        },
        async getUser(){
          this.loadingUser=true;
          let response=await this.$store.dispatch('getUser', this.$route.params.id);
          this.initUserLocal(response)
          return response;
        }
      },
      computed: {
        user() {
          // let user=this.$store.state.user;
          if (this.loadingUser){
            return undefined;
          }
          let user;

          if (this.$store.state.isAdmin && this.$route.params.id!==undefined /*&& parseInt(this.$route.params.id)!==this.$store.getters.getUser.id*/){

            if (this.$store.state.workingUser!==undefined && this.$store.state.workingUser.id===this.$route.params.id ){
              user=this.$store.state.workingUser;
              this.initUserLocal(user);
              return user;
            }else{
              console.log("retrieve user");
              this.getUser();
              // this.$store.dispatch('getUser', this.$route.params.id);
              return undefined;
            }
          }else{
            if (this.$store.state.workingUser!==undefined ){
              console.log("updated user");
              user=this.$store.state.workingUser;
              this.initUserLocal(user);
              this.log(user)
              return user;
            }
          }
          user=this.$store.getters.getUser;
          this.initUserLocal(user);
          return user;
        },
      },
    }
</script>

<style scoped>

</style>